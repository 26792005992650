import {
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import {Home} from "./Home";
import {Form, Pending, Success, AlreadyPending, AlreadySubscribed, UnknownError} from "./Signup";


function App() {
  return (
      <Routes>
          <Route path="/" element={<Home/>}>
              <Route>
                  <Route index element={<Form/>} /> {/* This is the default route */}
                  <Route path="about" element={<Form/>}/>
                  <Route path="pending" element={<Pending/>}/>
                  <Route path="success" element={<Success/>}/>
                  <Route path="already-pending" element={<AlreadyPending/>}/>
                  <Route path="already-subscribed" element={<AlreadySubscribed/>}/>
                  <Route path="*" element={<Navigate to="/" />} /> {/* Catch-all route */}
              </Route>
          </Route>
      </Routes>
  );
}

export default App;