import {Fragment, useState} from "react";
import {
    Box,
    Dialog, DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Column} from "./elements/Column";
import {Row} from "./elements/Row";
import {Outlet, useLocation} from "react-router-dom";

export const Home = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:1250px)');
    const [open, setOpen] = useState(location.pathname == "/about");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Row sx={{minHeight: "100vh"}}>
            {!isMobile && <img
                style={{
                    minHeight: "100%",
                    width: "50vw",
                    objectFit: "cover",
                }}
                src="https://travelmate3140.s3.eu-central-1.amazonaws.com/assets/signup-image.jpg"
                alt="signup image"
            />}

            <Column sx={{alignItems: "flex-start"}}>
                <Row sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <img
                        style={{
                            width: isMobile ? "200px" : "300px",
                            margin: isMobile ? "25px 0 0 15px" : "30px 0 0 30px",
                        }}
                        src="https://travelmate3140.s3.eu-central-1.amazonaws.com/assets/logo/rectangle.svg"
                    />
                    <Box
                        sx={{
                            padding: isMobile ? "20px 20px 3px 10px" : "15px 25px 3px 10px",
                            cursor: "pointer",
                            height: "fit-content",
                            color: "grey",
                            "&:hover": {
                                color: "black",
                            }
                        }}
                        onClick={handleOpen}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                textDecoration: "underline",
                            }}
                        >
                            About
                        </Typography>
                    </Box>
                    <Dialog onClose={handleClose} open={open}>
                        <DialogTitle>
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogTitle sx={{paddingLeft: 0}}>About Travelmate</DialogTitle>
                            <DialogContentText sx={{marginBottom: "15px"}}>
                                At Travelmate, we understand the complexities and challenges of
                                planning the perfect trip. The world is vast, and uncovering its
                                hidden gems can be daunting, time-consuming, and often overwhelming.
                                That's where we step in.
                            </DialogContentText>
                            <DialogContentText sx={{marginBottom: "15px"}}>
                                We aim to provide a seamless and comprehensive platform for discovering and sharing travel itineraries, catering to both passionate travelers seeking unique experiences and creators looking to share their expertise.
                            </DialogContentText>
                            <DialogContentText sx={{marginBottom: "15px"}}>
                                We want to simplify the process for creators to upload and monetize their itineraries, while offering travelers an intuitive way to discover plans that match their preferences, making travel planning more enjoyable and efficient.
                            </DialogContentText>
                            <DialogContentText sx={{marginBottom: "15px"}}>
                                Join us in making travel easier, more personalized, and truly unforgettable.
                            </DialogContentText>
                            <DialogContentText >
                                Travelmate — <i>travel like a local.</i>
                            </DialogContentText>
                            <DialogContentText sx={{marginBottom: "15px"}}>
                                <a href="mailto:info@travelmate.global">info@travelmate.global</a>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Row>
                <Column sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: isMobile ? "100vw" : "50vw",
                }}>
                    <Outlet/>
                </Column>
                {isMobile && <img
                    style={{
                        width: "100vw",
                        height: "150px",
                        objectFit: "cover",
                    }}
                    src="https://travelmate3140.s3.eu-central-1.amazonaws.com/assets/signup-image.jpg"
                    alt="signup image"
                />}
            </Column>

        </Row>
    </Fragment>
}