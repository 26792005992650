import * as React from 'react';
import {useContext} from "react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";


const SnackbarContext = React.createContext({});

export const  SnackbarProvider = ({children}) => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [iconType, setIconType] = React.useState("");

    const iconTypes = {
        success: <DoneIcon/>,
        error: <ErrorOutlineIcon/>,
        warning: <WarningAmberIcon />,
        info: <InfoIcon/>
    }

    const handleOpen = (message, icontype) => {
        setIconType(icontype)
        setMessage(message)
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={{
            open: handleOpen,
        }}>
            {children}
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: "right"}}
                autoHideDuration={10000}
                open={open}
                onClose={handleClose}
                style={{
                    "& .MuiAlertAction": {
                        padding: "0px"
                    },
                }}
                action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <MuiAlert
                    onClose={handleClose}
                    severity="info"
                    icon={iconTypes[iconType]}
                    elevation={6}
                    variant="filled"
                    color={iconType}
                    sx={{
                        width: '100%',
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontSize: "15px",
                    }}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}

const useSnackbar = () => useContext(SnackbarContext)
export default useSnackbar;