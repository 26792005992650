import CircularProgress from '@mui/material/CircularProgress';
import {Button} from "@mui/material";

export const LoadingButton = ({loading, ...props}) => {
    if (loading) return <CircularProgress />

    return (
        <Button {...props}>
            {props.children}
        </Button>
    );
}