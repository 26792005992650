import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {SnackbarProvider} from "./hooks/Snackbar";
import {MediaContextProvider} from "./hooks/MediaContext";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {BrowserRouter as Router} from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFA500',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <SnackbarProvider>
              <MediaContextProvider>
                  <Router>
                      <App />
                  </Router>
              </MediaContextProvider>
          </SnackbarProvider>
      </ThemeProvider>
  </React.StrictMode>
);

