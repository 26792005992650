import {Fragment, useState} from "react";
import {
    Box,
    Checkbox,
    TextField,
    Typography,
} from "@mui/material";
import {Row} from "./elements/Row";
import {Space} from "./elements/Space";
import EmailIcon from '@mui/icons-material/Email';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form"
import {Column} from "./elements/Column";
import useSnackbar from "./hooks/Snackbar";
import {LoadingButton} from "./elements/LoadingButton";
import {useMediaContext} from "./hooks/MediaContext";
import {useLocation, useNavigate} from "react-router-dom";

const Container = ({children}) => {
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;

    return <Box sx={{
        maxWidth: "750px",
        padding: isMobile ? "80px 40px" : "100px",
    }}>
        {children}
    </Box>
}

const Title = ({children}) => {
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;

    return <Typography variant={isMobile ? "h4" : "h3"}>
        {children}
    </Typography>
}

const BackButton = () => {
    const navigate = useNavigate();

    return <Box
        sx={{
            paddingBottom: "10px",
            cursor: "pointer",
            height: "fit-content",
            color: "grey",
            "&:hover": {
                color: "black",
            }
        }}
        onClick={() => navigate("/")}
    >
        <ArrowBackIcon/>
    </Box>

}

export const Form = () => {
    const navigate = useNavigate();
    const mediaContext = useMediaContext();
    const isMobile = mediaContext.isMobile;
    const [isLoading, setIsLoading] = useState(false);
    const snackbar = useSnackbar();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            const response= await fetch(`${process.env.REACT_APP_BACKEND_API}/mailchimp`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: data.email,
                }),
            });

            // Success
            if (response.status === 200) {
                return navigate("/pending", {state: {email: data.email}})
            }

            // Member already exists
            if (response.status === 409) {
                const body = await response.json();

                if (body.status === "subscribed") {
                    return navigate("/already-subscribed")
                }

                if (body.status === "pending") {
                    return navigate("/already-pending")
                }

                if (body.status === "unsubscribed") {
                    return navigate("/already-pending")
                }
            }

            // Invalid email address
            if (response.status === 422) {
                return snackbar.open("Invalid email address", "error")
            }

            snackbar.open("Unexpected error", "error")
        } catch (error) {
            snackbar.open("Network error", "error")
        } finally {
            setIsLoading(false);
        }
    }

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
            <Box>
                <Title>Join the Journey!</Title>
                <Space height="20px"/>
                <Typography>
                    Get exclusive sneak peeks into Travelmate's development process and be a part of our beta testing community, providing valuable feedback to shape a tool that meets your travel needs.
                </Typography>
            </Box>
            <Space height="20px"/>
            <Column>
                <TextField
                    label="Email"
                    sx={{
                        width: isMobile ? "250px" : "300px"
                    }}
                    {...register(
                        "email",
                        {
                            required: "This field is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                                message: "Invalid email address"
                            }
                        }
                    )}
                />
                {errors.email && errors.email.message && (
                    <Typography variant="caption" sx={{color: "red", marginTop: "5px"}}>
                        {errors.email.message}
                    </Typography>
                )}
            </Column>
            <Space height="20px"/>
            <Row sx={{
                alignItems: "center",
                gap: isMobile ? "" : "10px",
            }}>
                <Column>
                    <Row>
                        <Checkbox
                            {...register(
                                "checkbox",
                                {required: "This field is required"}
                            )}
                        />
                        <Typography variant="caption" sx={{color: "grey"}}>
                            By joining, I agree to receive updates from Travelmate and understand my data will
                            be used as per the{" "}
                            <a
                                href="https://privacy-policy.travelmate.global"
                                target="_blank" rel="noopener noreferrer"
                                style={{ color: 'inherit' }}
                            >Privacy Policy</a>.
                            I can unsubscribe at any time.
                        </Typography>
                    </Row>
                    {errors.checkbox && errors.checkbox.message && (
                        <Typography variant="caption" sx={{color: "red"}}>
                            {errors.checkbox.message}
                        </Typography>
                    )}
                </Column>

            </Row>
            <Space height="20px"/>
            <LoadingButton
                type="submit"
                variant="contained"
                startIcon={<EmailIcon/>}
                loading={isLoading}
                sx={{
                    padding: "8px 25px",
                    color: "white",
                    fontSize: "20px",
                    textTransform: "none",
                }}
            >
                Join!
            </LoadingButton>
        </Container>
    </form>
}

export const Pending = () => {
    // Grab the email from the location state
    const location = useLocation();
    const email = location.state?.email;

    return <Fragment>
        <Container>
            <Box>
                <BackButton/>
                <Title>Almost Done!</Title>
                <Space height="20px"/>
                <Typography>
                    {email
                        ? `Please check your inbox at ${email} and confirm your email address to complete the registration.`
                        : "Please check your inbox and confirm your email address to complete your registration."
                    }
                </Typography>
            </Box>
        </Container>
    </Fragment>
}

export const AlreadyPending = () => {
    return <Fragment>
        <Container>
            <Box>
                <BackButton/>
                <Title>Confirm Your Email</Title>
                <Space height="20px"/>
                <Typography>
                    Your email is already in our system.
                    To complete your registration, please check your inbox for the
                    confirmation email. Don't forget to look in your spam folder too.
                    Still not working? Contact us directly at {" "}
                    <a href="mailto:info@travelmate.global">info@travelmate.global</a>
                </Typography>
            </Box>
        </Container>
    </Fragment>
}

export const AlreadySubscribed = () => {
    return <Fragment>
        <Container>
            <Box>
                <BackButton/>
                <Title>You Are Already Subscribed!</Title>
                <Space height="20px"/>
                <Typography>
                    Everything is in order, your email is already registered in our system!
                </Typography>
            </Box>
        </Container>
    </Fragment>
}

export const Success = () => {
    return <Fragment>
        <Container>
            <Box>
                <BackButton/>
                <Title>Thanks for Joining!</Title>
                <Space height="20px"/>
                <Typography>
                    We'll send you a welcome email within a couple of days and keep you updated on our progress going forward.
                </Typography>
            </Box>
        </Container>
    </Fragment>
}