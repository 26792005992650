import {Box} from "@mui/material";

export const Space = (width = "50px", height = "50px", style, ...props) => {
    return <Box
        sx={{
            width: width,
            height: height,
            ...style
        }}
        {...props}></Box>
}